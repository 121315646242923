<template>
  <v-row class="adsense">
    <v-col cols="6">
      <span>{{ leftTitle }}</span>
      <h3 :class="color">{{ leftContent }}</h3>
    </v-col>
    <v-col cols="6">
      <span>{{ rightTitle }}</span>
      <h3 :class="color">{{ rightContent }}</h3>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
const Component = Vue.extend({
  props: ['leftTitle', 'rightTitle', 'leftContent', 'rightContent', 'color']
})
export default Component
</script>
<style scoped>
</style>
