import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoginView from '../views/Login/LoginView.vue'
import IndexView from '../views/Index/IndexView.vue'
import WaitingView from '../views/Wait/WaitScreen.vue'
import RefreshToken from '../views/RefreshToken/RefreshTokenView.vue'
import store from '../store/index'

Vue.use(VueRouter)
store.dispatch('getSession')

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      redirectsIfLoggedIn: true
    }
  },
  {
    path: '/',
    alias: '/index',
    name: 'index',
    component: IndexView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/refresh_token',
    alias: '/refresh_token',
    name: 'refresh_token',
    component: RefreshToken,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/waiting',
    name: 'waitign',
    component: WaitingView,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.redirectsIfLoggedIn)) {
    if (store.getters.getLogin) {
      next({ name: 'index' })
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getLogin) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
