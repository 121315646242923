<template>
  <v-container class="grey lighten-5" fluid>
    <v-row>
      <v-col
          cols="12"
          sm="4"
        >
        <v-card
          class="pa-2"
          outlined
          tile
          >
          <LayoutView domain="https://mujersaludable10.com" page="mujer" :hasSummary="true" index=0 :token="tokenBS" logo="https://mujersaludable10.com/wp-content/uploads/2022/02/Logo-mujer_500.png" height=60 view="203732808" adsense_report="accounts/pub-8680685574100925/reports/AMLQQUayRgoyTF0RNz3ATLXysCveMFlZs3j8OMMz9YxcPFOgf9yki7A" />
        </v-card>
      </v-col>
      <v-col
          cols="12"
          sm="4"
        >
        <v-card
          class="pa-2"
          outlined
          tile
          >
          <LayoutView domain="https://jardineriayhogar.com" page="jardineria" :hasSummary="false" index=1 :token="tokenBS" logo="https://jardineriayhogar.com/wp-content/uploads/2022/03/normal.png" height=50 view="262366535" adsense_report="accounts/pub-8680685574100925/reports/AMLQQUayRgoyTF0RNz3ATLXysCveMFlZs3j8OMMz9YxcPFOgf9yki7A" />
        </v-card>
      </v-col>
      <v-col
          cols="12"
          sm="4"
        >
        <v-card
          class="pa-2"
          outlined
          tile
          >
          <LayoutView domain="https://adelgazarencasa.co" page="adelgazar" :hasSummary="false" index=0 :token="tokenNW" logo="https://adelgazarencasa.co/wp-content/uploads/2019/01/Logo.png" height=60 view="148332950" adsense_report="accounts/pub-8114260720097713/reports/AMLQQUZRcCivKOEj-i6Dl4__Yx055E3UmLazBgye_-lh6aXNV-VYHeI" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutView from '../../components/LayoutView.vue'
import store from '../../store/index'

export default {
  data () {
    return {
      tokenNW: store.getters.getTokenNW,
      tokenBS: store.getters.getTokenBS
    }
  },
  components: {
    LayoutView
  }
}
</script>
