









import Vue from 'vue'
import TopBar from '../src/components/Layouts/TopBar.vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  components: {
    TopBar
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['getLogin'])
  }
})
