<template>
  <v-app-bar app>
    <v-btn
      class="mx-2"
      fab
      dark
      small
      color="primary"
      @click="logout()"
    >
      <v-icon dark>
        mdi-logout
      </v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <v-toolbar-title>
      <span style="font-family: 'Lato', sans-serif; font-weight: 400;">
        {{ getTotalUsers }}
        <v-icon>
          mdi-account
        </v-icon>
      </span>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

const Component = Vue.extend({
  name: 'TopBar',
  data () {
    return {
      drawer: null
    }
  },
  methods: {
    ...mapActions(['logoutSession']),
    logout () {
      this.logoutSession()
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters(['getTotalUsers'])
  }
})

export default Component
</script>
