import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token_nw: '',
    token_bs: '',
    earningMujerToday: 0,
    earningJardineriaToday: 0,
    earningMujerYesterday: 0,
    earningJardineriaYesterday: 0,
    totalUsersNW: 0,
    totalUsersMS: 0,
    totalUsersJH: 0
  },
  getters: {
    getLogin (state) {
      return state.token_nw !== '' && state.token_bs !== ''
    },
    getTokenNW (state) {
      return state.token_nw
    },
    getTokenBS (state) {
      return state.token_bs
    },
    getEarningsToday (state) {
      if (state.earningMujerToday !== 0 && state.earningJardineriaToday !== 0) {
        return (state.earningMujerToday + state.earningJardineriaToday).toFixed(2)
      } else {
        return 0.00
      }
    },
    getEarningsYesterday (state) {
      if (state.earningMujerYesterday !== 0 && state.earningJardineriaYesterday !== 0) {
        return (state.earningMujerYesterday + state.earningJardineriaYesterday).toFixed(2)
      } else {
        return 0.00
      }
    },
    getAverageMJ (state) {
      return moment()
    },
    getTotalUsers (state) {
      const total = state.totalUsersNW + state.totalUsersMS + state.totalUsersJH
      return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }
  },
  actions: {
    loginSessionNW (context, data) {
      context.commit('setSessionNW', data)
      localStorage.setItem('session', JSON.stringify(context.state))
    },
    loginSessionBS (context, data) {
      context.commit('setSessionBS', data)
      localStorage.setItem('session', JSON.stringify(context.state))
    },
    getSession (context) {
      const session = localStorage.getItem('session')
      if (session && typeof session === 'string' && session !== '') {
        const data = JSON.parse(session)
        context.commit('setGeneralSession', data)
      }
    },
    logoutSession (context) {
      context.commit('logoutSession')
    },
    reportEarningsMujerToday (context, value) {
      context.commit('setEarningMujerToday', value)
    },
    reportEarningsJardineriaToday (context, value) {
      context.commit('setEarningJardineriaToday', value)
    },
    reportEarningsMujerYesterday (context, value) {
      context.commit('setEarningMujerYesterday', value)
    },
    reportEarningsJardineriaYesterday (context, value) {
      context.commit('setEarningJardineriaYesterday', value)
    },
    sumTotalUsersNW (context, value) {
      context.commit('setTotalUsersNW', value)
    },
    sumTotalUsersMS (context, value) {
      context.commit('setTotalUsersMS', value)
    },
    sumTotalUsersJH (context, value) {
      context.commit('setTotalUsersJH', value)
    }
  },
  mutations: {
    setSessionNW (state, n) {
      state.token_nw = JSON.stringify(n)
    },
    setSessionBS (state, n) {
      state.token_bs = JSON.stringify(n)
    },
    setGeneralSession (state, n) {
      state.token_nw = n.token_nw
      state.token_bs = n.token_bs
    },
    logoutSession (state) {
      localStorage.clear()
      state.token_nw = ''
      state.token_bs = ''
    },
    setEarningMujerToday (state, n) {
      state.earningMujerToday = parseFloat(n)
    },
    setEarningJardineriaToday (state, n) {
      state.earningJardineriaToday = parseFloat(n)
    },
    setEarningMujerYesterday (state, n) {
      state.earningMujerYesterday = parseFloat(n)
    },
    setEarningJardineriaYesterday (state, n) {
      state.earningJardineriaYesterday = parseFloat(n)
    },
    setTotalUsersNW (state, n) {
      state.totalUsersNW = n
    },
    setTotalUsersMS (state, n) {
      state.totalUsersMS = n
    },
    setTotalUsersJH (state, n) {
      state.totalUsersJH = n
    }
  }
})
