<template>
  <v-app id='inspire'>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class='elevation-12'>
              <v-toolbar dark color='primary'>
                <v-toolbar-title>Inicio de sesión Analytics</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div class='text-center'>
                  <v-btn
                    v-if="!getTokenNW"
                    color='blue'
                    class='ma-2 white--text'
                    @click='login("nw")'
                  >
                    Iniciar sesión en personal
                    <v-icon
                      right
                      dark
                    >
                      mdi-google
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!getTokenBS"
                    color='pink'
                    class='ma-2 white--text'
                    @click='login("bs")'
                  >
                    Iniciar sesión en Mujer
                    <v-icon
                      right
                      dark
                    >
                      mdi-google
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { API_ENDPOINT } from '../../common/constants'

export default {
  mounted () {
    this.prepare()
  },
  data () {
    return {
      error: false,
      tokenNW: '',
      tokenBS: ''
    }
  },
  methods: {
    prepare: function (event) {
      window.gapi.load('client:auth2', function () {
        window.gapi.auth2.init({
          client_id: '775880209598-cgcam9mtsp7ijfgi9p6qisov9j77lrdr.apps.googleusercontent.com',
          plugin_name: 'login'
        })
      })
    },
    login (type) {
      var ga = window.gapi.auth2.getAuthInstance()

      ga.grantOfflineAccess({ scope: 'https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/adsense.readonly' }).then(async (code) => {
        const http = axios.create({
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })

        const params = new URLSearchParams()
        params.append('code', code.code)
        params.append('client_id', '775880209598-cgcam9mtsp7ijfgi9p6qisov9j77lrdr.apps.googleusercontent.com')
        params.append('client_secret', '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')
        params.append('redirect_uri', location.protocol + '//' + location.host)
        params.append('grant_type', 'authorization_code')

        const response = await http.post(
          'https://oauth2.googleapis.com/token',
          params
        )

        const data = response.data

        if (type === 'nw') {
          this.loginSessionNW(data)
        } else {
          this.loginSessionBS(data)
        }
        this.$router.push('/index')
      })
    },
    ...mapActions(['loginSessionNW', 'loginSessionBS']),
    handleLogout () {
      this.logoutSession()
    }
  },
  computed: {
    ...mapGetters(['getTokenNW', 'getTokenBS'])
  }
}
</script>
