<template>
  <div>
    <div style="height: 60px">
      <img :src="logo" :height="height" />
    </div>
    <h1>
      <ICountUp
        :delay="delay"
        :endVal="conectados"
        :options="options"
      />
    </h1>

    <v-row class="adsense">
      <v-col
          cols="6"
        >
        <span>Hoy</span>
        <h3>{{ estimated_earnings_today }} $USD</h3>
      </v-col>
      <v-col
          cols="6"
        >
        <span>Ayer</span>
        <h3>{{ estimated_earnings_yersterday }} $USD</h3>
      </v-col>
    </v-row>

    <HeadAndBlock
      v-if="this.page === 'mujer'"
      leftTitle="Total hoy"
      :leftContent="getEarningsToday + ' $USD'"
      rightTitle="Total ayer"
      :rightContent="getEarningsYesterday + ' $USD'"
      color="pinked"
      />

    <HeadAndBlock
      v-if="this.page === 'jardineria'"
      leftTitle="Promedio del mes"
      :leftContent="this.calculateAverage(estimatedMonth) + ' $USD'"
      rightTitle="Total mes"
      :rightContent="this.numberWithCommas(estimatedMonth) + ' $USD'"
      color="gardeni"
      />

    <HeadAndBlock
      v-if="this.page === 'adelgazar'"
      leftTitle="Promedio del mes"
      :leftContent="this.calculateAverage(estimatedMonth) + ' $USD'"
      rightTitle="Total mes"
      :rightContent="this.numberWithCommas(estimatedMonth) + ' $USD'"
      color="purpled"
      />

    <v-row class="adsense">
      <v-col cols="6">
        <h4><strong>{{ cpc_today }}</strong> cpc</h4>
        <h4><strong>{{ parseThousands(clicks_today) }}</strong> clicks</h4>
        <h4><strong>{{ parseBigNumbers(pages_view_today) }}</strong> page views</h4>
      </v-col>
      <v-col cols="6">
        <h4><strong>{{ cpc_yersterday }}</strong> cpc</h4>
        <h4><strong>{{ parseThousands(clicks_yersterday) }}</strong> clicks</h4>
        <h4><strong>{{ parseBigNumbers(pages_view_yersterday) }}</strong> page views</h4>
      </v-col>

      <v-col cols="12" class="d-flex d-sm-none">
        <v-btn block @click="toggleTable">
          {{ !showTable ? 'Ver' : 'Ocultar' }} páginas
        </v-btn>
      </v-col>

    </v-row>

    <div class="table" v-if="showTable">
      <v-data-table
        :headers="headers"
        :hide-default-header="this.windowWidth <= 598 ? true : false"
        :items="listadoDeNotas"
        :hide-default-footer="this.windowWidth <= 598 ? false : true"
        :disable-pagination="this.windowWidth <= 598 ? false : true"
        class="elevation-1"
      >
        <template v-slot:[`item.title`]="{ item }">
          <a :href="item.path" target="_blank" style="color: black; text-decoration: none;" class="hover-1">
            {{ item.title }}
          </a>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          <strong>{{ parseSimpleDate(item.created_at) }}</strong>
          <p style="font-size: 13px;">{{ item.author }}</p>
        </template>
        <template v-slot:[`item.posted_at`]="{ item }">
          <v-img
            :lazy-src="bucket + item.posted_by"
            height="30"
            width="30"
            class="rounded-image"
            :src="bucket + item.posted_by"
          ></v-img>
          {{ item.posted_at }}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import store from '../store/index'
import ICountUp from 'vue-countup-v2'
import HeadAndBlock from '@/components/HeadAndBlock.vue'
import axios from 'axios'
import moment from 'moment'
import { API_ENDPOINT, S3_ENDPOINT } from '@/common/constants'

const Component = Vue.extend({
  computed: {
    ...mapGetters(['getEarningsToday', 'getEarningsYesterday'])
  },
  props: ['logo', 'height', 'view', 'adsense_report', 'token', 'index', 'hasSummary', 'page', 'domain'],
  data () {
    return {
      bucket: S3_ENDPOINT,
      windowWidth: window.innerWidth,
      showTable: true,
      conectados: 0,
      delay: 400,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: '.',
        decimal: '',
        prefix: '',
        suffix: ''
      },
      sortBy: 'fat',
      sortDesc: false,
      headers: [
        {
          text: 'Título de la nota',
          align: 'start',
          value: 'title',
          width: '50%'
        },
        { text: 'Publicada', value: 'posted_at' },
        { text: 'Por', value: 'created_at' },
        { text: 'Users', value: 'users' }
      ],
      listadoDeNotas: [{}],
      estimated_earnings_today: 0,
      pages_view_today: 0,
      clicks_today: 0,
      cpc_today: 0,
      estimated_earnings_yersterday: 0,
      pages_view_yersterday: 0,
      clicks_yersterday: 0,
      cpc_yersterday: 0,
      estimatedMonth: 0
    }
  },
  components: {
    ICountUp,
    HeadAndBlock
  },
  mounted () {
    moment.locale('es_ES')
    this.startTrackingAnalytics()
    this.startTrackingAdsense('TODAY')
    this.startTrackingAdsense('YESTERDAY')
    this.startTrackingAdsense('MONTH_TO_DATE')

    if (this.windowWidth <= 598) {
      this.showTable = false
    }
  },
  methods: {
    async loopNotasForMetaData () {
      const httpLaravel = axios.create({
        headers: {
          'Content-Type': 'application/json'
        }
      })

      await httpLaravel.post(API_ENDPOINT + 'getPostData', {
        data: this.listadoDeNotas
      }).then((data) => {
        this.listadoDeNotas = data.data
      }).catch((error) => {
        console.log('error')
        console.log(error)
      })
    },
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    calculateAverage (monthly) {
      const today = moment()
      if (today.date() === 1) {
        return this.numberWithCommas(monthly.toFixed(2))
      } else {
        const daysToYesterday = parseInt(today.subtract(1, 'days').format('D'))
        const hourToNow = today.hour()
        const pieceOfDay = parseFloat(hourToNow / 24)
        const totalToToday = daysToYesterday + pieceOfDay
        return this.numberWithCommas((monthly / totalToToday).toFixed(2))
      }
    },
    ...mapActions(['reportEarningsMujerToday', 'reportEarningsJardineriaToday', 'reportEarningsMujerYesterday', 'reportEarningsJardineriaYesterday', 'sumTotalUsersNW', 'sumTotalUsersMS', 'sumTotalUsersJH']),
    toggleTable () {
      console.log('actual ' + this.showTable + ' nuevo ' + !this.showTable)
      this.showTable = !this.showTable
    },
    parseBigNumbers (value) {
      var entero = Math.floor(value / 1000)
      return entero + 'k'
    },
    parseSimpleDate (value) {
      if (value === '') {
        return ''
      } else {
        var date = moment(value).format('MMM YYYY')
        return date
      }
    },
    parseThousands (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async startTrackingAdsense (date) {
      if (this.$route.name !== 'index') return

      const http = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + JSON.parse(this.token).access_token
        }
      })

      await http.get('https://content-adsense.googleapis.com/v2/' + this.adsense_report + '/saved:generate?dateRange=' + date + '&currencyCode=USD&reportingTimeZone=ACCOUNT_TIME_ZONE&key=AIzaSyBVKOWvjtB9d7N1y08wD_o-ta6CguF-k8A')
        .then((response) => {
          const res = response.data
          if (date === 'TODAY') {
            this.estimated_earnings_today = res.rows[this.index].cells[1].value
            this.pages_view_today = res.rows[this.index].cells[2].value
            this.clicks_today = res.rows[this.index].cells[7].value
            this.cpc_today = res.rows[this.index].cells[8].value
            if (this.page === 'mujer') {
              this.reportEarningsMujerToday(this.estimated_earnings_today)
            }
            if (this.page === 'jardineria') {
              this.reportEarningsJardineriaToday(this.estimated_earnings_today)
            }
          } else if (date === 'YESTERDAY') {
            this.estimated_earnings_yersterday = res.rows[this.index].cells[1].value
            this.pages_view_yersterday = res.rows[this.index].cells[2].value
            this.clicks_yersterday = res.rows[this.index].cells[7].value
            this.cpc_yersterday = res.rows[this.index].cells[8].value
            this.impressions_yersterday = res.rows[this.index].cells[4].value
            if (this.page === 'mujer') {
              this.reportEarningsMujerYesterday(this.estimated_earnings_yersterday)
            }
            if (this.page === 'jardineria') {
              this.reportEarningsJardineriaYesterday(this.estimated_earnings_yersterday)
            }
          } else {
            // This month
            this.estimatedMonth = res.totals.cells[1].value
          }

          setTimeout(() => {
            this.startTrackingAdsense('TODAY')
            this.startTrackingAdsense('YESTERDAY')
            this.startTrackingAdsense('MONTH_TO_DATE')
          }, 600000)
        }).catch((error) => {
          var statusCode = error.response.status
          if (statusCode === 401) {
            this.$router.push('/refresh_token')
          } else {
            // this.$router.push('/waiting')
          }
        })
    },
    async startTrackingAnalytics () {
      if (this.$route.name !== 'index') return
      if (this.view === '262366535') return

      const http = axios.create({
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + JSON.parse(this.token).access_token
        }
      })

      await http.get('https://content-analytics.googleapis.com/analytics/v3/data/realtime?ids=ga%3A' + this.view + '&metrics=rt:activeUsers&dimensions=rt:pageTitle,rt:pagePath&key=AIzaSyBVKOWvjtB9d7N1y08wD_o-ta6CguF-k8A')
        .then((response) => {
          const obj = []
          const res = response.data
          res.rows.forEach(element => {
            obj.push({
              title: element[0],
              users: element[2],
              path: this.domain + element[1],
              posted_at: '',
              created_at: '',
              author: '',
              posted_by: ''
            })
          })
          obj.sort((a, b) => b.users - a.users)
          this.conectados = parseInt(res.totalsForAllResults['rt:activeUsers'])

          if (this.domain === 'https://mujersaludable10.com') {
            this.sumTotalUsersMS(this.conectados)
          }

          if (this.domain === 'https://jardineriayhogar.com') {
            this.sumTotalUsersJH(this.conectados)
          }

          if (this.domain === 'https://adelgazarencasa.co') {
            this.sumTotalUsersNW(this.conectados)
          }

          this.listadoDeNotas = obj.filter((el) => parseInt(el.users) >= 4)

          this.loopNotasForMetaData()

          setTimeout(() => {
            this.startTrackingAnalytics()
          }, 60000)
        }).catch((error) => {
          var statusCode = error.response.status
          if (statusCode === 401) {
            this.$router.push('/refresh_token')
          } else {
            // this.$router.push('/waiting')
          }
        })
    }
  }
})

export default Component
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap');
img {
  display: block;
  margin: auto;
}
h1 {
  display: block;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 50px;
}
.table {
  margin-top: 30px;
}
.adsense span {
  display: block;
  text-align: center;
  color: #606060;
}
.v-data-table__mobile-table-row {
  display: none;
}
.adsense h3 {
  display: block;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: #000000;
  font-weight: 900;
}
.adsense h4 {
  display: block;
  text-align: center;
  font-family: 'Lato', sans-serif;
  color: #000000;
  font-weight: 400;
}
.adsense {
  margin-bottom: 0px;
}
@media only screen and (max-width: 800px) {
  img {
    width: 100% !important;
    height: auto !important;
  }
  h1 {
    font-size: 60px;
  }
  .adsense span {
    font-size: 22px !important;
  }
  .adsense h3 {
    font-size: 24px !important;
  }
  .adsense h4 {
    font-size: 20px !important;
  }
}
</style>

<style>
.v-data-table__mobile-table-row {
  padding: 10px 0px !important;
  border-bottom: 1px solid #d1d1d1;
}
.v-data-table__mobile-row {
  display: table-cell !important;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}
.pinked {
  color: #d24176 !important;
}
.gardeni {
  color: #20a299 !important;
}
.purpled {
  color: #f46279 !important;
}
@media only screen and (max-width: 800px) {
  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child {
    border-bottom: none !important;
  }
}
.hover-1 {
  transition: color 0.5s ease;
}
.hover-1:hover {
  color: rgb(0, 48, 82) !important;
  text-decoration: underline !important;
}
.rounded-image {
  border-radius: 50%;
}
</style>
