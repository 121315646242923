<template>
  <v-app>
    <v-container class="fill-height">
      <v-layout column class="fill-height">
        <v-flex
          class="flex darken-3 overflow-auto text-center d-flex flex-column align-center justify-center"
        >
          <h3>Error de API</h3>
          <br />
          Por favor espera durante {{ countDown }} segundos.
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { API_ENDPOINT } from '../../common/constants'
import store from '../../store/index'

export default {
  data () {
    return {
      countDown: 60
    }
  },
  methods: {
    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.$router.push('/index')
      }
    }
  },
  created () {
    this.countDownTimer()
  }
}
</script>
