<template>
  <v-app>
    <v-container class="fill-height">
      <v-layout column class="fill-height">
        <v-flex
          class="white--text flex darken-3 overflow-auto text-center d-flex flex-column align-center justify-center"
        >
          <v-progress-circular
            :size="60"
            :width="6"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { API_ENDPOINT } from '../../common/constants'
import store from '../../store/index'

export default {
  mounted () {
    this.refresh('nw')
    this.refresh('bs')
  },
  data () {
    return {
      tokenNW: JSON.parse(store.getters.getTokenNW).refresh_token,
      tokenBS: JSON.parse(store.getters.getTokenBS).refresh_token,
      isNW: false,
      isBS: false
    }
  },
  watch: {
    isNW () {
      this.checkIfNeedRedirect()
    },
    isBS () {
      this.checkIfNeedRedirect()
    }
  },
  methods: {
    checkIfNeedRedirect () {
      if (this.isNW === true && this.isBS === true) {
        this.$router.push('/index')
      }
    },
    async refresh (type) {
      const http = axios.create({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      const params = new URLSearchParams()
      params.append('refresh_token', type === 'nw' ? this.tokenNW : this.tokenBS)
      params.append('client_id', '775880209598-cgcam9mtsp7ijfgi9p6qisov9j77lrdr.apps.googleusercontent.com')
      params.append('client_secret', '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')
      params.append('grant_type', 'refresh_token')

      const response = await http.post(
        'https://oauth2.googleapis.com/token',
        params
      )

      var data = response.data

      if (type === 'nw') {
        data.refresh_token = this.tokenNW
        this.loginSessionNW(data)
        this.isNW = true
      } else {
        data.refresh_token = this.tokenBS
        this.loginSessionBS(data)
        this.isBS = true
      }
    },
    ...mapActions(['loginSessionNW', 'loginSessionBS'])
  }
}
</script>
